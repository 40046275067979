import * as React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Layout from '../components/layout'
import Card from '../components/card'

const AboutPage = ({ data }) => {
  return (
    <Layout pageTitle="About">
      <Card>
        <div className="mdx p-2 space-y-3">
          <MDXRenderer>
            {data.mdx.body}
          </MDXRenderer>
        </div>
      </Card>
    </Layout>
  )
}

export const query = graphql`
  query {
    mdx(slug: {eq: "about"}) {
      body
    }
  }
`

export default AboutPage
