import React from "react"
import { ThemeToggler } from "gatsby-plugin-dark-mode"

const ThemeToggle = () => {
  return (
    <ThemeToggler>
      {({ theme, toggleTheme }) => (
        <label className="w-fit flex items-center cursor-pointer relative" for="toggle-switch">
          <input className="sr-only" id="toggle-switch" type="checkbox" onChange={e => toggleTheme(e.target.checked ? 'dark' : 'light')} checked={theme === 'dark'}/>
          <div className="toggle-bg bg-teal-700 border-2 border-teal-700 h-4 w-9 md:h-6 md:w-11 rounded-full"/>
        </label>
      )}
    </ThemeToggler>
  )
}

export default ThemeToggle
