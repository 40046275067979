import * as React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import ThemeToggle from './theme-toggle'

const Layout = ({ pageTitle, children }) => {
  const data = useStaticQuery(graphql`
   query {
     site {
       siteMetadata {
         title
         tagline
         author
       }
     }
   }
  `)

  return (
    <div className="min-h-screen bg-gradient-to-br from-neutral-200 to-zinc-300 dark:from-zinc-800 dark:to-zinc-900">
      <title>{pageTitle} | {data.site.siteMetadata.title}</title>
      <header className="pt-2 pb-2 text-white bg-gradient-to-br from-teal-600 to-zinc-900 dark:from-teal-400 dark:to-zinc-800">
        <div className="mx-2 grid grid-cols-2">
          <div>
            <ThemeToggle />
          </div>
          <div className="text-xs md:text-sm text-right uppercase">
            <Link to="/about">About</Link>
          </div>
        </div>
        <div className="my-4 max-w-4xl mx-auto text-center space-y-2">
          <Link to="/" className="font-serif text-5xl font-bold md:text-6xl">{data.site.siteMetadata.title}</Link>
          <div className="italic text-xs text-gray-300">{data.site.siteMetadata.tagline} by {data.site.siteMetadata.author}</div>
        </div>
      </header>
      <main className="max-w-5xl flex-auto p-2 space-y-2 sm:p-3 sm:space-y-3 lg:m-auto lg:py-2">
        {children}
      </main>
    </div>
  )
}

export default Layout
