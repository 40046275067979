import * as React from 'react'

const Card = ({children}) => {
  return (
    <div className="p-2 rounded-md bg-zinc-50 text-zinc-800 dark:bg-zinc-700 dark:text-zinc-200 shadow-md">
      {children}
    </div>
  )
}

export default Card
